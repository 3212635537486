import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const ObviousAintSoObvious = ({data}) => (
 <div> 

    <SEO 
    title="Obvious Ain't So Obvious — Phil Murphy"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="It's okay to say something obvious, because it's not so apparent after all — Phil Murphy">
        </meta>

        <meta name="og:description" content="It's okay to say something obvious, because it's not so apparent after all — Phil Murphy">
        </meta>

        <meta name="description" content="It's okay to say something obvious, because it's not so apparent after all — Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="It's okay to say something obvious, because it's not so apparent after all — Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>



    


    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>


        <p className="EssayTitlePage">Obvious Ain't So Obvious</p>

        <div className="P_style">

        <div className="QuoteBlockPurple">
          <div className="QuoteContent"><span className="QuoteHighlight">"You will never fully convince someone that he is wrong; only reality can"</span></div>
          <div className="QuoteOwner">— Nassim Nicholas Taleb</div>
        </div>

        <br />

        <p>I noticed 2 things in daily conversations:</p>

        <p>First, when we try to explain our point of view and we want to make a final point to reinforce our statement, and this statement is somewhat a normal thing to us, we often add "of course it's obvious, but..."</p>

        <p>Or second, when we try to express our vision or try to point out some "regularity" in actions and that they should actually be different, we often hear in response something like "this is not rocket science, so..." or "yeah, this is kinda obvious".</p>

        <p>The problem is that it's not actually <b>so obvious</b>.</p>

        <p>When we think that something is wrong, or some actions of another person are contradicting our beliefs or desires, we try to explain why it's good or bad by using our own big picture vision.</p>

        <p>But this someone doesn't have the same vision. This someone has gone through a completely dissimilar path and has entirely different information in mind.</p>

        <p>So it's impossible to impose any idea to make some person completely believe in it, or completely turn it down. And here's why:</p>

        <p>For instance, let's imagine the simplest situation that can happen in life. </p>

        <p>There is a man who smokes weed, he doesn't see anything wrong with it and fully aware of his actions. The other person tells him every time that it's harmful and he should quit.</p>

        <p>So here's what the information's chain of both people looks like:</p>

        <div className="EssayImagePage">
          <a href="../static/4282971036bcbd110f5011e701838384/d9bdf/InformationsChain.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.InformationsChain.childImageSharp.fluid}
            />
          </a>
        </div>

        <p>They both went through different stages and obstacles that later affected their attitudes towards smoking pot. But the second person tries to use arguments that have been built for them for many years, and using them, they don't really affect the person who smokes, because he doesn't have those pieces of information that could make the arguments meaningful enough.</p>

        <p>So the only way a smoking person can come to the conscious realization that it can be bad and he needs to quit is only if he accidentally goes through all the same stages (or some parts) of information gathering as the second person.</p>


        <br />

        <p><h3>So what can we learn from this?</h3></p>
        
        <p>There is nothing that can be called "obvious".</p>

        <p>So it just doesn't make sense to spend time trying to explain that something is actually obvious, without actually trying to make it so. And yeah, although it's not possible to recreate the learning curve you went through once for the person you trying to "change", it's still possible to get someone on a similar path.</p>

        <p>All you have to do is realize that it will take time and you need a little luck.</p>

        <p>Then just start with a simple thing. Light the spark of some topic in which you want the person to become better and everything else will just snowball.</p>

        <p>A good starting point would be just showing the way you personally do things. For example, instead of trying to get someone to use some new TO-DO app, just show them how you're using it first, without putting pressure on them. It will light the spark and do its job!</p>


  
 



        </div>


        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default ObviousAintSoObvious

export const query = graphql`
  query {
  
    InformationsChain: file(relativePath: { eq: "InformationsChain.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }


  }
`